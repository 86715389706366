const MAX_POKEMONS = 151;

const API_PATH = 'https://api.npoint.io';

const TYPE_COLORS = {
    normal: "#A8A878",
    fire: "#F08030",
    water: "#6890F0",
    electric: "#F8D030",
    grass: "#78C850",
    ice: "#98D8D8",
    fighting: "#C03028",
    poison: "#A040A0",
    ground: "#E0C068",
    flying: "#A890F0",
    psychic: "#F85888",
    bug: "#A8B820",
    rock: "#B8A038",
    ghost: "#705898",
    dragon: "#7038F8",
    dark: "#705848",
    steel: "#B8B8D0",
    fairy: "#EE99AC",
};

const TYPE_COLORS_INV = {
    normal: "#212121",
    fire: "#212121",
    water: "#212121",
    electric: "#212121",
    grass: "#212121",
    ice: "#212121",
    fighting: "#FFFFFF",
    poison: "#FFFFFF",
    ground: "#212121",
    flying: "#212121",
    psychic: "#FFFFFF",
    bug: "#212121",
    rock: "#212121",
    ghost: "#FFFFFF",
    dragon: "#FFFFFF",
    dark: "#FFFFFF",
    steel: "#212121",
    fairy: "#212121",
};

const STAT_NAME_MAP = {
    hp: "HP",
    attack: "ATK",
    defense: "DEF",
    "special-attack": "SATK",
    "special-defense": "SDEF",
    speed: "SPD",
};

export {MAX_POKEMONS, TYPE_COLORS, TYPE_COLORS_INV, STAT_NAME_MAP, API_PATH}