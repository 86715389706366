import User from "./user"

const MAX_POKEMON = 151;
const listWrapper = document.querySelector(".list-wrapper");
const searchInput = document.querySelector("#search-input");
const numberFilter = document.querySelector("#number");
const nameFilter = document.querySelector("#name");
const notFoundMessage = document.querySelector("#not-found-message");

let users = [];

document.body.style.opacity='1'

document.addEventListener("DOMContentLoaded", () => {
  User.list().then(data => {
    users = data;
    displayUsers(data)

    return data;
  }).then(data => {
    searchInput.addEventListener("keyup", () => {
      const searchTerm = searchInput.value.toLowerCase();
      let filteredUsers = [];

      if (numberFilter.checked) {
        filteredUsers = users.filter((user) => user.number.toString().startsWith(searchTerm));
      } else if(nameFilter.checked) {
        filteredUsers = users.filter((user) => user.name.toLowerCase().startsWith(searchTerm));
      } else {
        filteredUsers = users;
      }

      displayUsers(filteredUsers);

      notFoundMessage.style.display = filteredUsers.length === 0 ? "block" : "none";

      document.querySelector(".search-close-icon").addEventListener("click", () => {
        searchInput.value = "";
        displayUsers(data);
        notFoundMessage.style.display = "none";
      });

    });
  });
});

let displayUsers = (users) => {
  listWrapper.innerHTML = "";

  users
  .sort((a, b) => a.number - b.number)
  .forEach(user => {
    const listItem = document.createElement("div");
    listItem.className = "list-item";
    listItem.innerHTML = `
        <div class="number-wrap">
            <p class="caption-fonts">#${user.number}</p>
        </div>
        <div class="img-wrap">
            <img src="${user.image}" alt="${user.name}" />
        </div>
        <div class="name-wrap">
            <p class="body3-fonts">${user.name}</p>
        </div>
    `;

    listItem.addEventListener("click", async () => {
      window.location.href = `./detail.html?id=${user.id}`;
    });

    listWrapper.appendChild(listItem);
  });
}