import {API_PATH} from "./constants"

let User = {
    list() {
        return fetch(`${API_PATH}/3b5cbc16ec0da4e52934`).then(res => res.json());
    },
    info(id) {
        return fetch(`${API_PATH}/${id}`).then(res => {
            if (res.ok) {
                return res.json();
            }

            throw new Error('Fetch request failed');
        }).then(data => data[0]);
    }
}

export default User;